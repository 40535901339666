
import {defineComponent,reactive,toRefs,onMounted } from "vue";
import {getIpAddress} from "@/api/index";
import { ElMessage } from 'element-plus';
// import { useStore } from './../store';
import { useStore } from "vuex";
interface deviceDataF{
    options:Array<any>,
    value:string,
    chooseIp:string,
}
export default defineComponent({
    name:'ServerChoose',
    components:{
    },
    setup(props,{emit}){
        const store = useStore();
        const deviceData:deviceDataF = reactive({
            options : [],
            value:'',  //弹窗展示
            chooseIp:'',
        });
        const data = toRefs(deviceData);
        onMounted(()=>{
            //getIpAddressFun();
        });
        const getIpAddressFun = async()=>{
            let params = {};
            // await getIpAddress(params).then((res:any)=>{
            //     if(res.code == 200){
            //         res.rows.map((res:any)=>{
            //             deviceData.options.push({
            //                 value:res.name,
            //                 lable:res.ip,
            //             });
            //         });
            //         if(res.rows.length > 0){
            //             if(!store.state.chooseIp){
            //                 deviceData.value = res.rows[0].name;
            //                 deviceData.chooseIp = res.rows[0].ip;
            //             }else{
            //                 deviceData.value = deviceData.options.find((op)=>{
            //                     return op.lable == store.state.chooseIp;
            //                 }).value;
            //             }
            //             // deviceData.chooseIp = res.rows[0].ip;
            //         }
            //     }else{
            //         if(res.msg){
            //             ElMessage.error(res.msg);
            //         }else{
            //             ElMessage.error('获取用ip地址列表失败');
            //         } 
            //     }
            // })
        }
        const valChange = (e:any)=>{
            deviceData.chooseIp = deviceData.options.find((op)=>{
                return op.value == e;
            }).lable;
            store.commit('CHOOSEIP',deviceData.chooseIp);
        }
        return {
            ...data,
            valChange
        }
    }
})
